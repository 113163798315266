import { IAdmin } from '../../types';
import { Box } from '@chakra-ui/react';
import ClinicianNavigation from '../ClinicianNavigation';
import EnvironmentWarning from './EnvironmentWarning';

interface IProps {
  children: React.ReactNode;
  clinician: IAdmin;
}

export default function ClinicianLayout({
  children,
  clinician,
}: IProps): JSX.Element {
  return (
    <Box
      height="100vh"
      background="white"
      display="grid"
      gridTemplateColumns="max-content auto"
    >
      <EnvironmentWarning />
      <ClinicianNavigation clinician={clinician} />
      <Box>{children}</Box>
    </Box>
  );
}
