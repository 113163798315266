import { Box } from '@chakra-ui/react';
import { isDevelopmentEnv, isStagingEnv } from '../../utils';

const Warning = ({ children }: { children: string }): JSX.Element => (
  <Box
    position="absolute"
    bg="yellow.400"
    fontSize="10px"
    fontWeight="bold"
    py={1}
    zIndex={1}
    w="240px"
    textAlign="center"
  >
    {children}
  </Box>
);

export default function EnvironmentWarning(): JSX.Element | null {
  const isDevelopment = isDevelopmentEnv();
  const isStaging = isStagingEnv();

  if (isDevelopment) {
    return <Warning>🛠 DEVELOPMENT</Warning>;
  }

  if (isStaging) {
    return <Warning>🚀 STAGING</Warning>;
  }

  return null;
}
