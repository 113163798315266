import { Box, Icon, Link, Text } from '@chakra-ui/react';
import {
  BsBoxArrowUpRight,
  BsFillCheckCircleFill,
  BsFillExclamationCircleFill,
} from 'react-icons/bs';
import { isProductionEnv } from '../../../utils';
import { CANVAS_DEV_URL, CANVAS_URL } from '../../../constants';

interface IProps {
  canvasId?: string;
  impiloId?: string;
}

const integrationDetailsByType: Record<string, any> = {
  canvas: {
    getIntegrationUrl: getCanvasUrl,
  },
  impilo: {
    getIntegrationUrl: getImpiloUrl,
  },
};

function getCanvasUrl(id: string): string {
  if (isProductionEnv()) {
    return `${CANVAS_URL}/patient/${id}`;
  }

  return `${CANVAS_DEV_URL}/patient/${id}`;
}

function getImpiloUrl(id: string): string {
  return `https://app.impiloplatform.com/patient/${id}`;
}

function Integration({
  id,
  name,
  url,
}: {
  id?: string;
  name: string;
  url: string;
}): JSX.Element {
  const IconType = id ? BsFillCheckCircleFill : BsFillExclamationCircleFill;
  const iconColor = id ? 'green.500' : 'red.500';
  const { getIntegrationUrl } = integrationDetailsByType[name];

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Icon as={IconType} color={iconColor} />

      {id ? (
        <Link
          display="flex"
          alignItems="center"
          color="blue.500"
          gap={1}
          href={getIntegrationUrl(id)}
          isExternal
        >
          <Text textTransform="capitalize">{name}</Text>
          <Icon as={BsBoxArrowUpRight} />
        </Link>
      ) : (
        <Text textTransform="capitalize">{name}</Text>
      )}
    </Box>
  );
}

export default function Integrations({
  canvasId,
  impiloId,
}: IProps): JSX.Element {
  return (
    <>
      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <Text fontWeight="bold" color="blackAlpha.500" flexShrink="0">
          Integrations
        </Text>
        <Box borderTop="1px solid" borderColor="blackAlpha.200" width="100%" />
      </Box>

      <Box display="flex" flexDirection="column" gap={3}>
        <Integration name="canvas" url="test" id={canvasId} />
        <Integration name="impilo" url="test" id={impiloId} />
      </Box>
    </>
  );
}
