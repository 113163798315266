import React, { useState } from 'react';
import { Box, Switch, Text, useDisclosure, useToast } from '@chakra-ui/react';
import ConfirmationDialog from './ConfirmationDialog';
import { createCallableFunction } from '../../../api';

interface IProps {
  isActive: boolean;
  onUpdate: () => Promise<void>;
  patientUid: string;
}

export default function ContactInformation({
  isActive,
  onUpdate,
  patientUid,
}: IProps): JSX.Element {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(isActive);
  const { isOpen, onClose, onOpen } = useDisclosure();

  async function handleConfirmChangeStatus() {
    setIsLoading(true);

    const updatePatientStatus = createCallableFunction('updatePatientRecord');

    try {
      await updatePatientStatus({
        data: {
          isActive: isChecked,
        },
        patientUid,
      });

      onUpdate();

      toast({
        description: `Patient ${isChecked ? 'activated' : 'deactivated'}.`,
        isClosable: true,
        position: 'top-left',
        status: 'success',
        title: 'Success',
        variant: 'left-accent',
      });
    } catch (error) {
      toast({
        description: `An error occurred ${
          isChecked ? 'activating' : 'deactivating'
        } the patient.`,
        isClosable: true,
        position: 'top-left',
        status: 'error',
        title: 'Uh Oh',
        variant: 'left-accent',
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  }

  function handleChange({
    currentTarget,
  }: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = currentTarget;

    setIsChecked(checked);
    onOpen();
  }

  function handleOnCancel() {
    onClose();
    setIsChecked(!isChecked);
  }

  return (
    <>
      <ConfirmationDialog
        isChecked={isChecked}
        isLoading={isLoading}
        isOpen={isOpen}
        onCancel={handleOnCancel}
        onConfirm={handleConfirmChangeStatus}
      />

      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <Text fontWeight="bold" color="blackAlpha.500" flexShrink="0">
          Status
        </Text>
        <Box borderTop="1px solid" borderColor="blackAlpha.200" width="100%" />
      </Box>

      <Box display="flex" gap={2}>
        <Switch
          isChecked={isChecked}
          colorScheme="purple"
          onChange={handleChange}
          disabled={isLoading}
        />
        <Text>Patient is {isActive ? 'active' : 'inactive'}</Text>
      </Box>
    </>
  );
}
