import { useState } from 'react';
import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { IPatient } from '../../../types';
import { createCallableFunction } from '../../../api';

interface IProps {
  onUpdate: () => Promise<void>;
  patient: IPatient;
}

export default function EnrollmentPrompt({
  onUpdate,
  patient,
}: IProps): JSX.Element | null {
  const { isActive, isEnrolled, uid } = patient;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  if (!isActive || isEnrolled) {
    return null;
  }

  async function handleOnDeactivate() {
    setIsLoading(true);

    const updatePatientStatus = createCallableFunction('updatePatientRecord');

    try {
      await updatePatientStatus({
        data: {
          isActive: false,
        },
        patientUid: uid,
      });

      toast({
        description: 'Patient deactivated.',
        isClosable: true,
        position: 'top-left',
        status: 'success',
        title: 'Success',
        variant: 'left-accent',
      });

      onUpdate();
    } catch (error) {
      toast({
        description: 'An error occurred deactivating the patient.',
        isClosable: true,
        position: 'top-left',
        status: 'error',
        title: 'Uh Oh',
        variant: 'left-accent',
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleOnEnroll() {
    setIsLoading(true);

    const enrollPatient = createCallableFunction('patientEnrollment');

    try {
      await enrollPatient({
        uid,
      });

      toast({
        description: 'Patient enrolled.',
        isClosable: true,
        position: 'top-left',
        status: 'success',
        title: 'Success',
        variant: 'left-accent',
      });

      onUpdate();
    } catch (error) {
      toast({
        description: 'An error occurred enrolling the patient.',
        isClosable: true,
        position: 'top-left',
        status: 'error',
        title: 'Uh Oh',
        variant: 'left-accent',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box bg="gray.100" p={6} rounded="md" mb={4}>
      <Text mb={2} fontWeight="bold">
        Enroll Patient
      </Text>

      <Text mb={4} fontSize="sm">
        This patient is not yet enrolled. Click Enroll to attest that the
        patient accepted legal agreements and passed the New Patient Visit.
      </Text>

      <Box display="flex" justifyContent="flex-end" gap={4}>
        <Button
          colorScheme="red"
          onClick={handleOnDeactivate}
          isLoading={isLoading}
        >
          Deactivate
        </Button>

        <Button
          colorScheme="green"
          onClick={handleOnEnroll}
          isLoading={isLoading}
        >
          Enroll
        </Button>
      </Box>
    </Box>
  );
}
