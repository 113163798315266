import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import {
  ClinicianLogin,
  ClinicianDashboard,
  ClinicianPatients,
  ClinicianPractitioners,
  ClinicianReports,
} from '../pages';
import { NotFoundRedirect } from '../clinicianComponents';
import { IAdmin } from '../types';

function ClinicianApp() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [adminData, setAdminData] = useState<IAdmin | undefined>();

  useEffect(() => {
    async function fetchAdminData() {
      const { currentUser } = auth;

      if (!currentUser) {
        setAdminData(undefined);
        return;
      }

      setIsLoading(true);

      try {
        const adminRef = doc(db, 'admins', currentUser.uid);
        const adminDocument = await getDoc(adminRef);
        const admin = {
          uid: adminDocument.id,
          ...adminDocument.data(),
        } as IAdmin;

        setAdminData(admin);
      } catch (error) {
        // @TODO: Error handling
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchAdminData();
  }, [isAuthenticated]);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ChakraProvider>
      <Routes>
        <Route path="/" element={<ClinicianLogin />} />
        {adminData && (
          <>
            <Route
              path="/clinician/dashboard"
              element={<ClinicianDashboard clinician={adminData} />}
            />
            <Route
              path="/clinician/patients"
              element={<ClinicianPatients clinician={adminData} />}
            />
            <Route
              path="/clinician/patients/:uid"
              element={<ClinicianPatients clinician={adminData} />}
            />
            <Route
              path="/clinician/practitioners"
              element={<ClinicianPractitioners clinician={adminData} />}
            />
            <Route
              path="/clinician/reports"
              element={<ClinicianReports clinician={adminData} />}
            />
          </>
        )}
        <Route path="*" element={<NotFoundRedirect />} />
      </Routes>
    </ChakraProvider>
  );
}

export default ClinicianApp;
