import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import DatePicker from 'react-date-picker';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { BsCalendar4Event } from 'react-icons/bs';
import PhoneNumberInput from './PhoneNumberInput';
import { PatientEmailInput } from '../../../clinicianComponents';
import { getStateOptions } from '../../../utils';
import { createCallableFunction } from '../../../api';

function formatDateOfBirth(date: Date): string {
  return dayjs(date).format('YYYY-MM-DD');
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IFormValues {
  firstName: string;
  lastName: string;
  dateOfBirth?: Date;
  sexAtBirth: string;
  email: string;
  phoneNumber: string;
  addressLineOne: string;
  addressLineTwo: string;
  addressCity: string;
  addressState: string;
  addressZipCode: string;
  clinicalNote?: string;
}

export default function AddPatientForm({
  isOpen,
  onClose,
}: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: undefined,
      sexAtBirth: '',
      email: '',
      phoneNumber: '',
      addressLineOne: '',
      addressLineTwo: '',
      addressCity: '',
      addressState: '',
      addressZipCode: '',
      clinicalNote: '',
    },
    onSubmit: handleOnSubmit,
  });

  async function handleOnSubmit(values: IFormValues) {
    setIsLoading(true);

    const patientIntake = createCallableFunction('patientIntake');
    const dateOfBirth = formatDateOfBirth(values.dateOfBirth as Date);

    const patientData = {
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth,
      sexAtBirth: values.sexAtBirth,
      email: values.email,
      phoneNumber: values.phoneNumber,
      address: {
        lineOne: values.addressLineOne,
        lineTwo: values.addressLineTwo,
        city: values.addressCity,
        state: values.addressState,
        zipCode: values.addressZipCode,
      },
      clinicalNote: values.clinicalNote,
    };

    try {
      const { uid } = await patientIntake(patientData);

      toast({
        description: `${values.firstName} ${values.lastName} has been added as a patient.`,
        isClosable: true,
        position: 'top-right',
        status: 'success',
        title: 'Success!',
        variant: 'left-accent',
      });

      handleOnClose();

      navigate(`/clinician/patients/${uid}`);
    } catch (error) {
      toast({
        description: 'An error occurred while adding the new patient.',
        isClosable: true,
        position: 'top-right',
        status: 'error',
        title: 'Uh oh!',
        variant: 'left-accent',
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleOnClose() {
    formik.resetForm();
    setIsLoading(false);
    onClose();
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={handleOnClose}
      size="lg"
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <form onSubmit={formik.handleSubmit}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>New patient</DrawerHeader>

          <DrawerBody>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              rowGap={5}
              columnGap={3}
              mb={5}
            >
              <FormControl isRequired>
                <FormLabel>First name</FormLabel>
                <Input
                  id="firstName"
                  placeholder="First name"
                  onChange={formik.handleChange}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Last name</FormLabel>
                <Input
                  id="lastName"
                  placeholder="Last name"
                  onChange={formik.handleChange}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Date of birth</FormLabel>
                <Input
                  id="dateOfBirth"
                  name="dateOfBirth"
                  onChange={(value) => {
                    formik.setFieldValue('dateOfBirth', value || '');
                  }}
                  value={formik.values.dateOfBirth}
                  placeholder="YYYY-MM-DD"
                  as={DatePicker}
                  openCalendarOnFocus={false}
                  calendarIcon={<BsCalendar4Event />}
                  clearIcon={null}
                  showLeadingZeros={true}
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Sex at birth</FormLabel>
                <Select id="sexAtBirth" onChange={formik.handleChange}>
                  <option value="" disabled selected>
                    Sex at birth
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                  <option value="unknown">Unknown</option>
                </Select>
              </FormControl>

              <PatientEmailInput
                onBlur={(email: string) => formik.setFieldValue('email', email)}
              />

              <PhoneNumberInput
                onBlur={(phoneNumber: string) =>
                  formik.setFieldValue('phoneNumber', phoneNumber)
                }
              />
            </Box>

            <Box mb={5}>
              <FormControl isRequired>
                <FormLabel>Address</FormLabel>
                <Input
                  id="addressLineOne"
                  placeholder="Line one"
                  mb={2}
                  onChange={formik.handleChange}
                />
              </FormControl>
              <Input
                id="addressLineTwo"
                placeholder="Line two"
                mb={2}
                onChange={formik.handleChange}
              />
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                columnGap={3}
              >
                <FormControl isRequired>
                  <Input
                    id="addressCity"
                    placeholder="City"
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <Select id="addressState" onChange={formik.handleChange}>
                    <option value="" disabled selected>
                      Select State
                    </option>
                    {getStateOptions()}
                  </Select>
                </FormControl>
                <FormControl isRequired>
                  <Input
                    id="addressZipCode"
                    placeholder="Zip code"
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Box>
            </Box>

            <FormControl mb={6}>
              <FormLabel>Intake notes</FormLabel>
              <Textarea id="clinicalNote" onChange={formik.handleChange} />
              <FormHelperText>
                This note will appear below the patient's name in Canvas.
              </FormHelperText>
            </FormControl>

            <Box p={6} bg="gray.50" borderRadius={6}>
              <Text fontWeight="bold" mb={2}>
                About patient creation
              </Text>

              <Text>
                Submitting this form creates linked patient records in Canvas,
                Source, and Impilo. If a patient record has already been created
                in one of these apps, please do not submit this form.
              </Text>
            </Box>
          </DrawerBody>

          <DrawerFooter display="flex" justifyContent="space-between">
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="green" type="submit" isLoading={isLoading}>
              Submit
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
}
