import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Heading, Icon, Image, Text } from '@chakra-ui/react';
import { FaGoogle } from 'react-icons/fa';
import {
  deleteUser,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { auth } from '../../firebase';
import { MARLEY_DOMAIN, verifyToken } from '../../utils';
import logo from '../../assets/marley-logo-purple.svg';

export default function ClinicianLogin() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const { currentUser } = auth;

    if (currentUser && !isLoading) {
      navigate('/clinician/patients');
    }
  }, [navigate, isLoading]);

  async function handleClick() {
    setIsLoading(true);

    const googleProvider = new GoogleAuthProvider();

    googleProvider.setCustomParameters({
      hd: MARLEY_DOMAIN,
    });

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const isVerifiedUser = await verifyToken(credential?.accessToken);

      if (isVerifiedUser) {
        navigate('/clinician/patients');
      } else {
        await deleteUser(auth.currentUser!);
        await signOut(auth);
      }
    } catch (error: any) {
      // @TODO: Error handling
      console.log(error.code, error.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="#F4FAFE"
    >
      <Box textAlign="center">
        <Image src={logo} display="inline-block" mb={10} />
        <Heading as="h2" mb={2}>
          Admin Portal
        </Heading>

        <Text color="gray.500" mb={10}>
          Sign in with your corporate account to view patient data.
        </Text>

        <Button onClick={handleClick} isLoading={isLoading}>
          <Box display="flex" alignItems="center" gap={2}>
            <Icon as={FaGoogle} />
            Sign In With Google
          </Box>
        </Button>
      </Box>
    </Box>
  );
}
