import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { IPatient } from '../types';

export default async function getPatient(uid: string): Promise<IPatient> {
  const ref = doc(db, 'patients', uid);
  const patientDoc = await getDoc(ref);

  return {
    uid: patientDoc.id,
    ...patientDoc.data(),
  } as IPatient;
}
