import { signOut } from 'firebase/auth';
import { NavLink as ReactRouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import {
  BsPeopleFill,
  BsPersonCircle,
  BsPieChart,
  BsPower,
} from 'react-icons/bs';
import { auth } from '../../firebase';
import { IAdmin } from '../../types';
import logo from '../../assets/marley-logo-white.svg';

interface IProps {
  clinician: IAdmin;
}

const NAVIGATION_LINKS = [
  // @NOTE: Hiding unused navigation items
  // {
  //   icon: BsGrid,
  //   path: '/clinician/dashboard',
  //   text: 'Dashboard',
  // },
  {
    icon: BsPeopleFill,
    path: '/clinician/patients',
    text: 'Patients',
  },
  // {
  //   icon: BsClipboard,
  //   path: '/clinician/practitioners',
  //   text: 'Practitioners',
  // },
  {
    icon: BsPieChart,
    path: '/clinician/reports',
    text: 'Reports',
  },
];

export default function ClinicianNavigation({
  clinician,
}: IProps): JSX.Element {
  const navigate = useNavigate();

  async function handleClick() {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      // @TODO: Error handling
      console.log(error);
    }
  }

  return (
    <Box display="flex" flexDirection="column" bg="gray.800" width="240px">
      <Box pt={10} pb={6} textAlign="center">
        <Image src={logo} h={12} m="auto" mb={2} />
        <Heading color="#FFFFFF" fontSize="xl">
          Admin Portal
        </Heading>
      </Box>

      <Box
        as="nav"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={4}
      >
        {NAVIGATION_LINKS.map((link) => (
          <Link
            key={link.path}
            as={ReactRouterLink}
            to={link.path}
            display="flex"
            alignItems="center"
            gap={2}
            color="whiteAlpha.800"
            fontSize="sm"
            transition="all 250ms"
            borderRadius={6}
            _activeLink={{
              fontWeight: 'bold',
              background: 'gray.700',
            }}
            _hover={{
              background: 'gray.700',
            }}
            width="200px"
            mb={2}
            px={7}
            py={2}
          >
            <Icon as={link.icon} /> {link.text}
          </Link>
        ))}
      </Box>

      <Box
        display="flex"
        justifyContent="cener"
        mt="auto"
        mx={4}
        borderTop="1px solid"
        borderColor="whiteAlpha.200"
      >
        <Menu>
          <MenuButton w="100%">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={2}
              px={4}
              py={4}
              color="whiteAlpha.800"
              fontSize={24}
            >
              <Icon as={BsPersonCircle} />

              <Box>
                <Text fontSize="sm" fontWeight="bold">
                  {clinician.firstName} {clinician.lastName}
                </Text>
              </Box>
            </Box>
          </MenuButton>

          <MenuList>
            <MenuItem onClick={handleClick}>
              <Link
                variant="outline"
                display="flex"
                alignItems="center"
                gap={1}
              >
                <Icon as={BsPower} /> Log Out
              </Link>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
}
