import { useState } from 'react';
import { Box, Radio, RadioGroup, Text, useToast } from '@chakra-ui/react';
import { httpsCallable } from 'firebase/functions';
import { CommunicationPreference } from '../../../types';
import { functions } from '../../../firebase';

interface IProps {
  communicationPreference: CommunicationPreference;
  patientUid: string;
  phoneNumber: string;
  email: string;
  address: {
    lineOne: string;
    lineTwo?: string;
    city: string;
    state: string;
    zipCode: string;
  };
}

export default function ContactInformation({
  communicationPreference,
  patientUid,
  phoneNumber,
  email,
  address,
}: IProps): JSX.Element {
  const toast = useToast();
  const [preference, setPreference] = useState<CommunicationPreference>(
    communicationPreference || CommunicationPreference.SMS,
  );

  async function handlePreferenceChange(value: CommunicationPreference) {
    setPreference(value);

    const updatePatient = httpsCallable(functions, 'updatePatientRecord');

    try {
      await updatePatient({
        patientUid,
        communicationPreference: value,
      });
    } catch (error) {
      toast({
        description: `An error occurred while updating the patient's communication preferences`,
        position: 'top-right',
        status: 'error',
        title: 'Uh Oh',
        variant: 'left-accent',
      });
    }
  }

  return (
    <>
      <Box display="flex" alignItems="center" gap={2} mb={3}>
        <Text fontWeight="bold" color="blackAlpha.500" flexShrink="0">
          Contact information
        </Text>
        <Box borderTop="1px solid" borderColor="blackAlpha.200" width="100%" />
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="min-content auto"
        gridColumnGap={4}
        gridRowGap={2}
        ml={8}
      >
        <Text>Phone</Text>
        <Text>{phoneNumber}</Text>

        <Text>Email</Text>
        <Text>{email}</Text>

        <Text>Preference</Text>
        <RadioGroup
          display="flex"
          flexDir="column"
          gap={1}
          value={preference}
          onChange={handlePreferenceChange}
        >
          <Radio value={CommunicationPreference.SMS}>
            <Box textTransform="uppercase">{CommunicationPreference.SMS}</Box>
          </Radio>
          <Radio value={CommunicationPreference.EMAIL}>
            <Box textTransform="capitalize">
              {CommunicationPreference.EMAIL}
            </Box>
          </Radio>
        </RadioGroup>

        <Text>Address</Text>
        <Box>
          <Text>{address.lineOne}</Text>
          {address.lineTwo && <Text>{address.lineTwo}</Text>}
          <Text>
            {address.city}, {address.state} {address.zipCode}
          </Text>
        </Box>
      </Box>
    </>
  );
}
