import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { BsEye, BsEyeSlash, BsSearch } from 'react-icons/bs';
import PatientListItem from './PatientListItem';
import { IPatient } from '../../../types';

interface IProps {
  patients: IPatient[];
}

export default function PatientsList({ patients }: IProps): JSX.Element {
  const [filteredPatients, setFilteredPatients] =
    useState<IPatient[]>(patients);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isShowingInactive, setIsShowingInactive] = useState<boolean>(false);

  useEffect(() => {
    const filtered = patients
      .filter((patient) => {
        const fullName =
          `${patient.firstName} ${patient.lastName}`.toLowerCase();

        return fullName.includes(searchValue);
      })
      .filter((patient) => {
        if (isShowingInactive) {
          return true;
        } else {
          return patient.isActive === true;
        }
      });

    setFilteredPatients(filtered);
  }, [patients, searchValue, isShowingInactive]);

  function handleOnSearchChange({
    currentTarget,
  }: React.SyntheticEvent<HTMLInputElement>) {
    setSearchValue(currentTarget.value.toLowerCase());
  }

  function handleOnToggleStatus() {
    setIsShowingInactive(!isShowingInactive);
  }

  if (patients.length === 0) {
    return (
      <Box
        bg="white"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={4}
      >
        <Spinner
          color="#241647"
          size="xl"
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
        />
        <Text color="gray.500" fontSize="lg">
          Loading patients
        </Text>
      </Box>
    );
  }

  return (
    <Box p={5} overflowY="scroll">
      <Box mb={5} display="flex" gap={3}>
        <InputGroup>
          <InputLeftElement children={<Icon as={BsSearch} />} />
          <Input
            placeholder="Search patients by name"
            onChange={handleOnSearchChange}
          />
        </InputGroup>

        <Button
          onClick={handleOnToggleStatus}
          flexShrink={0}
          display="flex"
          gap={2}
        >
          {isShowingInactive ? (
            <>
              <Icon as={BsEyeSlash} /> Hide Inactive
            </>
          ) : (
            <>
              <Icon as={BsEye} /> Show Inactive
            </>
          )}
        </Button>
      </Box>

      <Box>
        {filteredPatients.map((patient) => (
          <Box key={patient.uid} mb={4}>
            <PatientListItem patient={patient} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
