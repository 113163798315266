import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { collection, onSnapshot } from 'firebase/firestore';
import { ClinicianLayout, EmptyState } from '../../clinicianComponents';
import PageHeader from './PageHeader';
import PatientsList from './PatientsList';
import PatientPane from './PatientPane';
import { AdminRole, IAdmin, IPatient } from '../../types';
import { db } from '../../firebase';

interface IProps {
  clinician: IAdmin;
}

export default function ClinicianPatients({ clinician }: IProps) {
  const [patients, setPatients] = useState<IPatient[]>([]);
  const isClinicianRole = clinician.role === AdminRole.CLINICIAN;

  useEffect(() => {
    const patientsRef = collection(db, 'patients');

    const unsub = onSnapshot(patientsRef, (querySnapshot) => {
      const patients: IPatient[] = [];

      querySnapshot.forEach((doc) => {
        patients.push({
          ...doc.data(),
          uid: doc.id,
        } as IPatient);
      });

      setPatients(patients);
    });

    return () => unsub();
  }, []);

  return (
    <ClinicianLayout clinician={clinician}>
      {isClinicianRole ? (
        <>
          <PageHeader />

          <Box
            height="calc(100vh - 81px)"
            display="grid"
            gridTemplateColumns="1fr 1fr"
          >
            <PatientsList patients={patients} />
            <PatientPane />
          </Box>
        </>
      ) : (
        <Box
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <EmptyState type="forbidden" />
        </Box>
      )}
    </ClinicianLayout>
  );
}
