import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

export { default as getPatientApi } from './getPatient';
export { default as getPatientsApi } from './getPatients';

export const createCallableFunction = <T = any>(
  name: string,
): ((requestBody: T) => Promise<any>) => {
  const callable = httpsCallable(functions, name);

  return async (requestBody: T) => {
    const { data }: { data: any } = await callable(requestBody);

    if (data.httpErrorCode) {
      throw new Error('An unknown error occurred');
    } else {
      return data;
    }
  };
};
