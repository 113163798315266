import { FocusEvent, useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {
  BsFillCheckCircleFill,
  BsFillExclamationCircleFill,
} from 'react-icons/bs';
import InputMask from 'react-input-mask';

function isPhoneNumberValid(input: string): boolean {
  // @NOTE: A valid phone number will be 10 digits long after removing hyphens and underscores
  const parsedInput = input.replaceAll(/[-_]/g, '');

  return parsedInput.length === 10;
}
interface IProps {
  onBlur: (phoneNumber: string) => void;
  defaultValue?: string;
}

export default function PhoneNumberInput({
  onBlur,
  defaultValue,
}: IProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isInputValid, setIsInputValid] = useState<boolean>(false);

  function handleOnBlur(event: FocusEvent<HTMLInputElement>) {
    setErrorMessage('');
    setIsInputValid(false);

    const { value } = event.target;

    if (isPhoneNumberValid(value)) {
      setIsInputValid(true);
      onBlur(value);
    } else {
      setErrorMessage('A valid phone number is required');
    }
  }

  return (
    <FormControl isRequired isInvalid={!!errorMessage}>
      <FormLabel>Phone number</FormLabel>

      <InputGroup>
        <Input
          id="phoneNumber"
          type="tel"
          placeholder="Phone number"
          defaultValue={defaultValue}
          as={InputMask}
          mask="999-999-9999"
          onBlur={handleOnBlur}
        />

        {errorMessage && (
          <InputRightElement
            children={<Icon as={BsFillExclamationCircleFill} color="red.300" />}
          />
        )}

        {isInputValid && (
          <InputRightElement
            children={<Icon as={BsFillCheckCircleFill} color="green.400" />}
          />
        )}
      </InputGroup>

      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}
