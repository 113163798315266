import { Box } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import PatientDetails from '../PatientDetails';
import VendorLinks from '../VendorLinks';
import { IPatient } from '../../../types';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

interface IProps {
  patient: IPatient;
}

export default function PatientListItem({ patient }: IProps): JSX.Element {
  const navigate = useNavigate();
  const { uid: uidParam } = useParams();

  const { uid, canvasId } = patient;
  const isSelected = uid === uidParam;

  function handleClick() {
    navigate(`/clinician/patients/${uid}`);
  }

  return (
    <Box
      onClick={handleClick}
      border="1px solid"
      borderColor={isSelected ? 'purple.500' : 'blackAlpha.200'}
      borderRadius={6}
      _hover={{
        cursor: 'pointer',
        borderColor: 'purple.500',
        transition: 'all 350ms',
        '.chakra-avatar': {
          color: 'purple.500',
          background: '#C9E5FC',
          transition: 'all 350ms',
        },
        '.vendor-links': {
          background: 'purple.50',
          borderColor: 'purple.500',
          transition: 'all 350ms',
        },
      }}
    >
      <PatientDetails {...patient} isSelected={isSelected} />

      <Box
        bg={isSelected ? 'purple.50' : 'gray.50'}
        py={2}
        px={5}
        borderTop="1px solid"
        borderColor={isSelected ? 'purple.500' : 'blackAlpha.200'}
        borderBottomRadius={6}
        className="vendor-links"
      >
        <VendorLinks canvasId={canvasId} />
      </Box>
    </Box>
  );
}
