import { Box, Button, Heading, Icon, useDisclosure } from '@chakra-ui/react';
import { BsPlusLg } from 'react-icons/bs';
import AddPatientFormDrawer from '../AddPatientFormDrawer';

export default function PatientsPageHeader(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="blackAlpha.200"
        p={5}
      >
        <Heading fontSize="3xl" display="flex" alignItems="center" gap={2}>
          Patients
        </Heading>

        <Button
          leftIcon={<Icon as={BsPlusLg} />}
          onClick={onOpen}
          colorScheme="blue"
        >
          New patient
        </Button>
      </Box>

      <AddPatientFormDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
}
