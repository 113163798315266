import { useCallback, useEffect, useState } from 'react';
import { Box, Image, Spinner, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import PatientDetails from '../PatientDetails';
import ContactInformation from './ContactInformation';
import EnrollmentPrompt from './EnrollmentPrompt';
import Status from './Status';
import Integrations from './Integrations';
import { getPatientApi } from '../../../api';
import { IPatient } from '../../../types';
import emptyStateImage from '../../../assets/patient_empty_state.svg';

const EmptyState = () => (
  <Box
    bg="gray.50"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    gap={5}
  >
    <Image src={emptyStateImage} />
    <Text color="blackAlpha.500" fontWeight="bold" fontSize="xl">
      No patient selected
    </Text>
  </Box>
);

const LoadingState = () => (
  <Box
    bg="gray.50"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    gap={5}
  >
    <Spinner
      color="#241647"
      size="xl"
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
    />
    <Text color="gray.500" fontSize="lg">
      Loading patient details
    </Text>
  </Box>
);

export default function PatientPane(): JSX.Element {
  const { uid } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patient, setPatient] = useState<IPatient>();

  const fetchPatient = useCallback(async () => {
    if (!uid) {
      return;
    }

    setIsLoading(true);

    const patient = await getPatientApi(uid);

    setPatient(patient);
    setIsLoading(false);
  }, [uid]);

  useEffect(() => {
    fetchPatient();
  }, [fetchPatient, uid]);

  async function onPatientDetailsUpdate() {
    fetchPatient();
  }

  if (isLoading) {
    return <LoadingState />;
  }

  if (patient) {
    return (
      <Box borderLeft="1px solid" borderColor="blackAlpha.200">
        <PatientDetails
          {...patient}
          isPatientPane
          onPatientDetailsUpdate={onPatientDetailsUpdate}
        />

        <Box borderTop="1px solid" borderColor="blackAlpha.200" m={5} pt={4}>
          <EnrollmentPrompt
            patient={patient}
            onUpdate={onPatientDetailsUpdate}
          />

          <Box mb={4}>
            <ContactInformation
              communicationPreference={patient.communicationPreference}
              patientUid={patient.uid}
              phoneNumber={patient.phoneNumber}
              email={patient.email}
              address={patient.address}
            />
          </Box>

          <Box mb={4}>
            <Integrations
              canvasId={patient.canvasId}
              impiloId={patient.impiloId}
            />
          </Box>

          <Status
            isActive={patient.isActive}
            onUpdate={onPatientDetailsUpdate}
            patientUid={patient.uid}
          />
        </Box>
      </Box>
    );
  }

  return <EmptyState />;
}
