import { Box, Heading } from '@chakra-ui/react';
import { ClinicianLayout } from '../../clinicianComponents';
import { IAdmin } from '../../types';
import PatientExperienceSurveyTable from './PatientExperienceSurveyTable';

interface IProps {
  clinician: IAdmin;
}

export default function ClinicianReports({ clinician }: IProps) {
  return (
    <ClinicianLayout clinician={clinician}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="blackAlpha.200"
        p={5}
      >
        <Heading fontSize="3xl" display="flex" alignItems="center" gap={2}>
          Reports
        </Heading>
      </Box>

      <Box p={5}>
        <PatientExperienceSurveyTable />
      </Box>
    </ClinicianLayout>
  );
}
