import { FocusEvent, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {
  BsFillCheckCircleFill,
  BsFillExclamationCircleFill,
} from 'react-icons/bs';
import { isValidEmail } from '../../../utils';
import { db } from '../../../firebase';

interface IProps {
  onBlur: (email: string) => void;
  defaultValue?: string;
}

async function queryPatientsCollectionByEmail(email: string): Promise<number> {
  const patientsRef = collection(db, 'patients');
  const q = query(patientsRef, where('email', '==', email));

  const patientsDocs = await getDocs(q);

  return patientsDocs.size;
}

export default function PatientEmailInput({
  onBlur,
  defaultValue,
}: IProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  async function handleOnBlur(event: FocusEvent<HTMLInputElement>) {
    setErrorMessage('');
    setIsSuccess(false);

    const { value } = event.target;

    if (!isValidEmail(value)) {
      setErrorMessage('A valid email is required');
      return;
    }

    const patientCollectionSize = await queryPatientsCollectionByEmail(value);

    if (patientCollectionSize !== 0) {
      setErrorMessage('Email is already in use.');
      onBlur('');
    } else {
      onBlur(value);
      setErrorMessage('');
      setIsSuccess(true);
    }
  }

  return (
    <FormControl isRequired isInvalid={!!errorMessage}>
      <FormLabel>Email address</FormLabel>
      <InputGroup>
        <Input
          id="email"
          type="email"
          placeholder="Email address"
          onBlur={handleOnBlur}
          defaultValue={defaultValue}
        />
        {errorMessage && (
          <InputRightElement
            children={<Icon as={BsFillExclamationCircleFill} color="red.300" />}
          />
        )}
        {isSuccess && (
          <InputRightElement
            children={<Icon as={BsFillCheckCircleFill} color="green.400" />}
          />
        )}
      </InputGroup>
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}
