import { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import {
  Heading,
  Tag,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import { db } from '../../firebase';
import { ISurvey } from '../../types';

function buildTableRows(surveys: ISurvey[]) {
  return surveys?.map((survey) => {
    const { answers, id, createdAt } = survey;
    const date = createdAt.toDate().toDateString();
    const comment = answers.additionalComments || 'N/A';

    return (
      <Tr key={id}>
        <Td>{date}</Td>
        <Td isNumeric>{answers.npsScore}</Td>
        <Td>
          <Tag textTransform="capitalize">{answers.reasonForNpsScore}</Tag>
        </Td>
        <Td maxWidth={400}>{comment}</Td>
      </Tr>
    );
  });
}

export default function PatientExperienceSurveyTable(): JSX.Element {
  const [surveyData, setSurveyData] = useState<ISurvey[]>([]);

  useEffect(() => {
    async function fetchSurveys() {
      const surveyCollection = collection(db, 'surveys');
      const q = query(surveyCollection, orderBy('createdAt', 'desc'));

      try {
        const surveyDocuments = await getDocs(q);
        const surveys: ISurvey[] = [];

        surveyDocuments.forEach((doc) => {
          surveys.push({
            id: doc.id,
            ...doc.data(),
          } as ISurvey);
        });

        setSurveyData(surveys);
      } catch (error) {
        // @TODO: Error handling
        console.error(error);
      }
    }

    fetchSurveys();
  }, []);

  return (
    <>
      <Heading as="h3" fontSize="lg" mb={3}>
        Patient Experience Survey
      </Heading>

      <TableContainer whiteSpace="pre-wrap">
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th isNumeric>NPS Score</Th>
              <Th>Reason for Score</Th>
              <Th>Comments</Th>
            </Tr>
          </Thead>
          <Tbody>{buildTableRows(surveyData)}</Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
