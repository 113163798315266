import { Routes, Route } from 'react-router-dom';
import { ClinicianApp } from './apps';
import './styles/index.css';

function App() {
  return (
    <Routes>
      <Route path="*" element={<ClinicianApp />} />
    </Routes>
  );
}

export default App;
