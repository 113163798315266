import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

interface IProps {
  isChecked: boolean;
  isLoading: boolean;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}

export default function ConfirmationDialog({
  isChecked,
  isLoading,
  isOpen,
  onCancel,
  onConfirm,
}: IProps): JSX.Element {
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {isChecked ? 'Activate' : 'Deactivate'} patient
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to {`${isChecked ? '' : 'de'}`}activate this
            patient? This action will {`${isChecked ? '' : 'de'}`}activate this
            patient's records across all platforms.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={onConfirm}
              ml={3}
              isLoading={isLoading}
            >
              {isChecked ? 'Activate' : 'Deactivate'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
