import { Box, Link, Text } from '@chakra-ui/react';
import { isProductionEnv } from '../../../utils';
import { CANVAS_DEV_URL, CANVAS_URL } from '../../../constants';

interface IProps {
  canvasId: string;
}

function getCanvasUrl(canvasId: string): string {
  if (isProductionEnv()) {
    return `${CANVAS_URL}/patient/${canvasId}`;
  }

  return `${CANVAS_DEV_URL}/patient/${canvasId}`;
}

export default function VendorLinks({ canvasId }: IProps): JSX.Element {
  const canvasUrl = getCanvasUrl(canvasId);

  return (
    <Box fontWeight="600" fontSize="xs" display="flex" gap={3}>
      <Text>Open in:</Text>
      <Link href={canvasUrl} isExternal>
        Canvas
      </Link>
    </Box>
  );
}
