import { Box } from '@chakra-ui/react';
import { ClinicianLayout, EmptyState } from '../../clinicianComponents';
import { IAdmin } from '../../types';

interface IProps {
  clinician: IAdmin;
}

export default function ClinicianDashboard({ clinician }: IProps) {
  return (
    <ClinicianLayout clinician={clinician}>
      <Box
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <EmptyState type="underConstruction" />
      </Box>
    </ClinicianLayout>
  );
}
