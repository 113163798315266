import { Box, Text } from '@chakra-ui/react';
import forbiddenAccess from '../../assets/forbidden_access.svg';
import underConstruction from '../../assets/under_construction.svg';

interface IProps {
  type: TEmptyState;
}

type TEmptyState = 'forbidden' | 'underConstruction';

interface IEmptyState {
  description: React.ReactNode;
  imageSrc: string;
  title: string;
}

const emptyStates: Record<TEmptyState, IEmptyState> = {
  forbidden: {
    imageSrc: forbiddenAccess,
    title: 'Forbidden access',
    description: (
      <>
        You don’t have permission to view this page. If this is an error, please
        reach out in the <u>#company-tools</u> Slack channel.
      </>
    ),
  },
  underConstruction: {
    imageSrc: underConstruction,
    title: 'Under construction',
    description: (
      <>
        This page is under construction. Please contact us in the{' '}
        <u>#product</u> Slack channel for more information.
      </>
    ),
  },
};

export default function EmptyState({ type }: IProps): JSX.Element {
  const { description, imageSrc, title } = emptyStates[type];

  return (
    <Box maxWidth="400px" textAlign="center" color="blackAlpha.500">
      <img src={imageSrc} alt={title} />

      <Text fontWeight="bold" mt={5} fontSize="xl">
        {title}
      </Text>

      <Text mt={5} fontSize="large">
        {description}
      </Text>
    </Box>
  );
}
