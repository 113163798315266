import isDevelopmentEnv from './isDevelopmentEnv';

export const MARLEY_DOMAIN = 'marleymedical.com';

export default async function verifyToken(token?: string): Promise<boolean> {
  if (isDevelopmentEnv()) {
    return true;
  }

  if (!token) {
    return false;
  }

  const response = await fetch(
    'https://www.googleapis.com/oauth2/v2/userinfo',
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  const { hd } = await response.json();

  return hd === MARLEY_DOMAIN;
}
