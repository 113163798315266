import { Avatar, Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IPatient } from '../../../types';
import EditPatientFormDrawer from '../EditPatientFormDrawer';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

interface IProps extends IPatient {
  isSelected?: boolean;
  isPatientPane?: boolean;
  onPatientDetailsUpdate?: () => void;
}

export default function PatientDetails({
  uid,
  phoneNumber,
  email,
  address,
  isActive = true,
  isSelected = false,
  createdAt,
  dateOfBirth,
  firstName,
  isPatientPane,
  lastName,
  sexAtBirth = 'unknown',
  onPatientDetailsUpdate,
}: IProps): JSX.Element {
  const fullName = `${firstName} ${lastName}`;
  const sexAbbreviation = sexAtBirth[0];
  const now = dayjs();
  const dob = dayjs(dateOfBirth, 'YYYY-MM-DD');
  const age = now.diff(dob, 'year');
  const joined = dayjs(createdAt.toDate()).fromNow();
  const avatarBgColor = isPatientPane || isSelected ? '#C9E5FC' : '#E2E8F0';
  const avatarColor = isPatientPane || isSelected ? 'purple.500' : '';

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
      <Box display="flex" gap={4} m={5} w="75%">
        <Avatar
          name={fullName}
          bg={avatarBgColor}
          color={avatarColor}
          size={isPatientPane ? 'lg' : 'md'}
        />
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Text fontSize={isPatientPane ? '2xl' : 'xl'} fontWeight="bold">
              {fullName}
            </Text>

            {!isActive && (
              <Box
                color="red"
                border="1px solid"
                borderColor="red"
                borderRadius={4}
                fontSize={10}
                fontWeight="bold"
                px="6px"
                py={1}
                ml={4}
              >
                Inactive
              </Box>
            )}
          </Box>
          <Box display="flex" gap={3} fontSize="xs">
            <Text>{age}</Text>
            <Text color="blackAlpha.200">•</Text>
            <Text>{dob.format('MMMM DD, YYYY')}</Text>
            <Text color="blackAlpha.200">•</Text>
            <Text casing="uppercase">{sexAbbreviation}</Text>
            <Text color="blackAlpha.200">•</Text>
            <Text>Joined {joined}</Text>
          </Box>
        </Box>
      </Box>
      {isPatientPane && (
        <Box textAlign="center" width="25%" m={5}>
          <Button
            bg="none"
            border="1px solid rgba(0, 0, 0, .25);"
            onClick={onOpen}
          >
            Edit
          </Button>

          <EditPatientFormDrawer
            isOpen={isOpen}
            onClose={onClose}
            patient={{
              uid,
              firstName,
              lastName,
              phoneNumber,
              email,
              address,
              dateOfBirth,
              sexAtBirth,
            }}
            onPatientDetailsUpdate={onPatientDetailsUpdate}
          />
        </Box>
      )}
    </Box>
  );
}
