import { Timestamp } from 'firebase/firestore';

type TSexAtBirth = 'male' | 'female' | 'other' | 'unknown';

export enum AdminRole {
  CLINICIAN = 'clinician',
  OTHER = 'other',
}

export enum CommunicationPreference {
  EMAIL = 'email',
  SMS = 'sms',
}

export interface IAddress {
  lineOne: string;
  lineTwo?: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface IPatient {
  uid: string;
  address: IAddress;
  canvasId: string;
  communicationPreference: CommunicationPreference;
  createdAt: Timestamp;
  customerIOId: string;
  dateOfBirth: string;
  deactivatedOn: Timestamp;
  email: string;
  enrolledOn: Timestamp;
  firstName: string;
  impiloId: string;
  isActive: boolean;
  isEnrolled: boolean;
  lastName: string;
  phoneNumber: string;
  sexAtBirth: TSexAtBirth;
  sourceId: string;
}

export interface IAdmin {
  uid: string;
  firstName: string;
  email: string;
  lastName: string;
  role: AdminRole;
}

export interface IBloodPressureReading {
  systolic: number;
  diastolic: number;
  id: string;
  heart_rate: number;
  reading_timestamp: string;
}

export interface IBloodPressureData {
  readings: [];
  count: number;
  size: number;
  page: number;
}

export interface ISurvey {
  id: string;
  answers: {
    additionalComments: string;
    npsScore: number;
    reasonForNpsScore: string;
  };
  createdAt: Timestamp;
  type: string;
}
